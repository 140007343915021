import React from "react";
import Img from "gatsby-image"
import Card from "react-bootstrap/Card";
const GalleryCard = (props) => (
    <Card>
        <Card.Img variant="top" fluid={props.image} as = {Img}/>
        <Card.Body>
        {/* <Card.Title style={{textAlign : "center"}}>{props.title}</Card.Title>
        <Card.Text>
            {props.data}
        </Card.Text> */}
        </Card.Body>
    </Card>
)

export default GalleryCard;