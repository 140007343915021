import React from "react";
import Layout from '../components/layout';
import GalleryCard from '../components/galleryCard';
import {graphql} from "gatsby";
import CardDeck from "react-bootstrap/CardDeck"
const aboutPage = ({data}) => (
    <Layout>
        <h1 style = {{textAlign : "center" }}>
            GALLERY
        </h1>
        <CardDeck>
            <GalleryCard title={"Heading"}   image={data.ThreesonsBuilding.childImageSharp.fluid}/> 

            <GalleryCard title={"Heading"}   image={data.ThreesonsBuilding2.childImageSharp.fluid}/>

           
        </CardDeck>

        <br />

        <CardDeck>
            <GalleryCard title={"Heading"}   image={data.ThreesonsNamePlate.childImageSharp.fluid}/> 

            {/* <GalleryCard title={"Heading"}   image={data.image1.childImageSharp.fluid}/> */}

             <GalleryCard title={"Heading"}   image={data.ThreesonsBuilding3.childImageSharp.fluid}/>
        </CardDeck>
    </Layout>
)

export default aboutPage;

export const query = graphql`
query {
    ThreesonsBuilding: file(relativePath: { eq: "ThreesonsBuilding.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    image1: file(relativePath: { eq: "gatsby-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ThreesonsBuilding2: file(relativePath: { eq: "ThreesonsBuilding2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ThreesonsBuilding3: file(relativePath: { eq: "ThreesonsBuilding3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ThreesonsNamePlate: file(relativePath: { eq: "ThreesonsNamePlate.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
}
`